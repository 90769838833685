<template>
  <div v-if="desktop" class="orders-summery">
    <div class="header">
        <div class="search">
            <span class="p-input-icon-left" style="width:100%">
            <i class="pi pi-search" />
            <InputText
                type="text"
                v-model="search"
                placeholder="חיפוש"
                style="width:100%"
            />
            </span>
        </div>
        <div class="date-from">
            <input type="date" v-model="fromDate" />
        </div>
        <div class="date-to">
            <input type="date" v-model="toDate" />
        </div>
        <div class="clear-dates">
            <Button
            label="איפוס תאריכים"
            class="p-button-secondary"
            @click="handleClearDates"
            />
        </div>
        <div class="export-to-excel">
            <Button
            icon="pi pi-file-excel"
            class="p-button-rounded p-button-success"
            @click="handleExportToCsv"
            />
        </div>
    </div>
    <div class="dashboard">
        <div class="frame total">
            <p>סה"כ הזמנות</p>
            <p>{{counter_values.total}}</p>
        </div>
         <div class="frame news">
            <p>הזמנות חדשות</p>
            <p>{{counter_values.open}}</p>
        </div>
        <div class="frame old">
            <p>הזמנות שטופלו</p>
            <p>{{counter_values.close}}</p>
        </div>
        <div class="frame process">
            <p>הזמנות בביצוע</p>
            <p>{{counter_values.delivery}}</p>
        </div>
        <div class="frame waiting">
            <p>הזמנות שגויות</p>
            <p>{{counter_values.problem}}</p>
        </div>
         <div class="frame current">
            <p>מספר הזמנות במסך נוכחי</p>
            <p>{{sorted_records.length}}</p>
        </div>
    </div>
    <div class="display-btns">
      <Button
        style="margin-left:50px"
        label="הצג חדשים"
        class="p-button-info"
        @click="handle_records('open')"
      />
      <Button
        style="margin-left:50px"
        label="הצג טופלו"
        class="p-button-success"
        @click="handle_records('close')"
      />
      <Button
        style="margin-left:50px"
        label="הצג בביצוע"
        class="p-button-warning"
        @click="handle_records('delivery')"
      />
      <Button
        label="הצג שגויים"
        class="p-button-danger"
        @click="handle_records('problem')"
      />
    </div>
    <div class="content">
         <table id="records">
             <tr>
                 <th @click="handleSortTable('תאריך הבקשה', !selectedSorted.order)">תאריך</th>
                 <th @click="handleSortTable('סניף', !selectedSorted.order)">סניף</th>
                 <th @click="handleSortTable('שם העובד', !selectedSorted.order)">שם העובד</th>
                 <th @click="handleSortTable('תפקיד', !selectedSorted.order)">תפקיד</th>
                 <th @click="handleSortTable('פותח ההזמנה', !selectedSorted.order)">פותח ההזמנה</th>
                 <th @click="handleSortTable('דואל', !selectedSorted.order)">דוא"ל</th>
                 <th @click="handleSortTable('מס מוצרים', !selectedSorted.order)">מס מוצרים</th>
                 <th>עריכה</th>
                 <th>סטטוס</th>
                 <th><Checkbox v-model="checkeAll" :binary="true" /></th>
             </tr>
              <h1  style="text-align:center;" v-if="sorted_records.length==0 && !isPending">אין רשומות</h1>
              <template v-for="record in sorted_records" :key="record.orderId">
                  <tr>
                    <td>{{record.createdAt.toDate().toLocaleString('he')}}</td>
                    <td>{{record.branche}}</td>
                    <td>{{record.worker_name}}</td>
                    <td>{{record.worker_role}}</td>
                    <td>{{record.owner}}</td>
                    <td>{{record.email}}</td>
                    <td>{{record.items.length}}</td>
                    <td><i class="pi pi-pencil" @click="handle_choosed_record(record)"></i></td>
                    <td v-if="record.status=='open'" style="color:green;">חדש</td>
                    <td v-if="record.status=='problem'" style="color:red;">דווח כשגוי</td>
                    <td v-if="record.status=='delivery'" style="color:#FFAC13;">הועבר לביצוע</td>
                    <td v-if="record.status=='close'" style="color:red;">טופל</td>
                    <td><Checkbox name="record" :value="record.orderId" v-model="check_records"/></td>
                  </tr>
              </template>
         </table>
    </div>
  </div>
    <OnlyForDesktop v-if="!desktop" />

    <Loader v-if="isPending"/>

    <Dialog
            header="חלון מחיקה"
            v-model:visible="showDeleteDialog"
            :style="{ width: '30vw' }"
            position="bottom"
        >
            <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
            <template #footer>
            <Button
                label="לא"
                @click="handleDeleteChecks('לא')"
                class="p-button-success"
            />
            <Button
                label="כן"
                @click="handleDeleteChecks('כן')"
                class="p-button-danger"
            />
            </template>
    </Dialog>

    <!-- orders to print -->
    <Dialog
      header="הזמנה"
      v-model:visible="show_record"
      :modal="true"
      position="top"
      :style="{ width: '90vw' }"
      style="z-index: 20;"
      @hide="selected_status=''"
    >
      <div id="orderToShow" class="orderToShow">
        <div class="print">
          <Button
            id="print-order-hide"
            label="הדפס הזמנה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="printOrder"
          />
        </div>
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה ביגוד והנעלה חדשה: סניף {{ selected_record.branche }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ selected_record.createdAt.toDate().toLocaleString("he") }}
          </p>
        </div>

        <div class="introduction">
          <h4>עובד רוסמן יקר/ה!</h4>
          <p>
            בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש
            ושלם וללא כל פגם.
          </p>
          <p>
            ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
            ומתחייב להשתמש להתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
          </p>
          <p>
            באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
            הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
            הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
            ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
          </p>
          <p>
            באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום
            השווה לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
          </p>
          <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
        </div>
        <div
          v-if="selected_record.problem"
          class="introduction"
          style="color:red;"
        >
          <p>הזמנה זו דווחה כשגוייה</p>
          <p>{{ selected_record.problem }}</p>
        </div>
        <div class="details-order">
          <p>שם העובד: {{ selected_record.worker_name }}</p>
          <p>ת"ז: {{ selected_record.idNumber }}</p>
          <p>תפקיד: {{ selected_record.worker_role }}</p>
        </div>
        <div
          class="notes-order"
          style="display:flex; gap:5px; flex-direction:column; margin-top:10px; background-color:whitesmoke; border-radius:5px; font-family: 'Rubik', sans-serif;"
        ></div>

        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; margin-top:1rem; width:100%; text-align:center; font-size:1.3rem; font-weight:500;"
          >
            <p style="width:25%">קוד</p>
            <p style="width:25%">שם ביגוד</p>
            <p style="width:25%">מידה</p>
            <p style="width:25%">כמות</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%;"
          ></div>
          <template v-for="product in selected_record.items" :key="product.barkod">
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
            >
              <p style="width:25%">{{ product.barkod }}</p>
              <p style="width:25%">{{ product.ItemName }}</p>
              <p style="width:25%">{{ product.selectedSizes }}</p>
              <p style="width:25%">{{ product.quantity }}</p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%; margin-bottom:10px;"
            ></div>
          </template>
          <div v-if="selected_record.signature" class="signature">
            <p>חתימת העובד:</p>
            <img :src="selected_record.signature" />
          </div>
        </div>

        <div
          class="print-tools"
          style="width:90vw; display:flex; gap:10px; justify-content:center; margin-top:1rem;"
        >
          <Dropdown v-model="selected_status" :options="status_options" optionLabel="name" placeholder="שינוי סטטוס" />
          <Button
            id="mark-btn-hide"
            label="עדכן"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-success"
            @click="flagOrderFinished"
          />
          <Button
            id="delete-order-hide"
            label="מחק הזמנה"
            style="width:140px; height:40px; font-size: 1.1rem;"
            class="p-button-danger"
            @click="showOrderDelete = !showOrderDelete"
          />
        </div>

        <!-- just for email -->
        <div class="form-email" id="form-email" v-show="false">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/rosman2021-d33ae.appspot.com/o/bigud_logo.png?alt=media&token=dd741df6-cd7d-4041-8e04-d0ed6c8e2e63"
            alt=""
          />
          <p>שלום {{ selected_record.owner }}</p>
          <p>
            בקשתך להזמנת ביגוד חדשה עבור {{ selected_record.worker_name }} שונתה
            בהצלחה ושונתה
            <span style="font-weight:bold;color:red;">לסטטוס {{selected_status.name}}</span>
            בעת הגעת מוצרי הביגוד לסניפך יש לעדכן את העובד אשר יקבל את הביגוד
            ולסגור את ההזמנה!
          </p>
          <p style="font-weight: bold;">
            שים לב! אספקת הביגוד ו/או הנעלה תגיע לסניפך תוך שבוע ימים לאחר
            אישורה!
          </p>
          <p>
            נא לעקוב אחר העדכונים מתוך המערכת המגיעים למייל שציינת:
            {{ selected_record.email }}
          </p>
          <p>טופל ע"י {{ user.display_name }}, דוא"ל:{{ user.email }}</p>
        </div>
      </div>

      <!-- just for print -->
      <div id="orderToPrint" v-show="false">
        <div
          class="order-title"
          style="display:flex; justify-content:center; flex-direction:column; gap:10px; align-items:center; font-family: 'Rubik', sans-serif;"
        >
          <p style="font-size:1.5rem;">
            הזמנה ביגוד והנעלה חדשה: סניף {{ selected_record.branche }}
          </p>
          <p style="font-size:1.25rem;">
            תאריך: {{ selected_record.createdAt.toDate().toLocaleString() }}
          </p>
        </div>

        <div class="introduction" style=" direction: rtl;">
          <h4>עובד רוסמן יקר/ה!</h4>
          <p>
            בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש
            ושלם וללא כל פגם.
          </p>
          <p>
            ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
            ומתחייב להשתמש להתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
          </p>
          <p>
            באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
            הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
            הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
            ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
          </p>
          <p>
            באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום
            השווה לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
          </p>
          <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
        </div>

        <div
          class="details-order"
          style=" direction: rtl; line-height: normal;"
        >
          <strong
            ><p style="margin:0">
              שם העובד: {{ selected_record.worker_name }}
            </p></strong
          >
          <strong
            ><p style="margin:0">ת"ז: {{ selected_record.idNumber }}</p></strong
          >
          <strong
            ><p style="margin:0">
              תפקיד: {{ selected_record.worker_role }}
            </p></strong
          >
        </div>

        <div
          class="products-list-order"
          style="display:flex; flex-direction:column; gap: 10px; margin-top:1rem; width:100%; direction:rtl; font-family: 'Rubik', sans-serif;"
        >
          <div
            class="orders-title"
            style="display:flex; width:100%; text-align:center; font-size:1.3rem; font-weight:500; margin:0;"
          >
            <p style="width:25%; ">קוד</p>
            <p style="width:25%">שם ביגוד</p>
            <p style="width:25%">מידה</p>
            <p style="width:25%">כמות</p>
          </div>
          <div
            class="line-sep"
            style="border:1px solid grey; width:100%; margin:0"
          ></div>

          <template v-for="product in selected_record.items" :key="product.barkod">
            <div
              class="product-order"
              style="display:flex; text-align:center; width:100%; font-size:1.25rem;"
            >
              <p style="width:25%; margin:0;">{{ product.barkod }}</p>
              <p style="width:25%; margin:0;">{{ product.ItemName }}</p>
              <p style="width:25%; margin:0;">{{ product.selectedSizes }}</p>
              <p style="width:25%; margin:0;">{{ product.quantity }}</p>
            </div>
            <div
              class="line-sep"
              style="border:1px solid grey; width:100%;"
            ></div>
          </template>
           <div
            v-if="selected_record.signature"
            class="signature"
            style="border: 1px solid black; margin-top:5px;"
          >
            <p>חתימת העובד:</p>
            <div class="sign"></div>
          </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      header="מחק הזמנה"
      v-model:visible="showOrderDelete"
      :modal="true"
      position="center"
    >
      <div
        class="delete-order"
        style="display:flex; flex-direction:column; gap: 15px;"
      >
        <p>האם אתה בטוח?</p>
        <Button
          label="טעות, סגור"
          style="width:200px; height:40px; font-size: 1.1rem;"
          class="p-button-info"
          @click="showOrderDelete = !showOrderDelete"
        />
        <Button
          label="מחק לגמריי"
          style="width:200px; height:40px; font-size: 1.1rem;"
          class="p-button-danger"
          @click="deleteOrderFinal"
        />
        <Button
          label="מחק ושחזר מלאי"
          style="width:200px; height:40px; font-size: 1.1rem;"
          class="p-button-danger"
          @click="deleteOrderRestore"
        />
      </div>
    </Dialog>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import { ref } from '@vue/reactivity';
import OnlyForDesktop from '../../../Not_responsible/OnlyForDesktop.vue'
import {projectFirestore,firebase,projectFunctions} from '../../../../firebase/config'
import { computed, onMounted, onUnmounted, watch } from '@vue/runtime-core';
import store from '../../../../store';
import {decrement,update_status_counter} from '../../../../Methods/bigud_funcs'
import { alert } from '../../../../Methods/Msgs';
import XLSX from "xlsx";
import Loader from '../../officeRequests/components/manage_side/loader/Loader.vue'

export default {
    components:{InputText,Checkbox,Dialog,Dropdown,Loader,OnlyForDesktop},
    setup(){
        const desktop = ref(true)
        const counter_values = ref({
          open:0,
          close:0,
          problem:0,
          delivery:0,
          total:0
        })
        const status_options=ref([
            {name:'טופל',code:'close'},
            {name:'חדש',code:'open'},
            {name:'דווח כשגוי',code:'problem'},
            {name:'הועבר לביצוע',code:'delivery'},
        ])
        const selected_status = ref('')
        const user = ref(computed(()=>{
            return store.getters.user
        }))
        const isPending = ref(false)
        const records = ref([])
        const search = ref('')
        const fromDate = ref('')
        const toDate = ref('')
        const checkeAll=ref(false)
        const check_records = ref([])
        const showDeleteDialog = ref(false)
        const selected_record = ref(null)
        const show_record = ref(false)
        const showOrderDelete = ref(false)

        const handleExportToCsv=()=>{
          let rows = JSON.parse(JSON.stringify(sorted_records.value))
          rows.forEach(row=>{
            row['תאריך'] = new Date(row.createdAt.seconds * 1000).toLocaleString('he').split('.').join('/');
            row['סניף'] = row.branche
            row['שם העובד'] = row.worker_name
            row['תפקיד'] = row.worker_role
            row['פותח ההזמנה'] = row.owner
            row['דואל'] = row.email
            row['מוצרים'] = row.items.map((item)=>`${item.ItemName} - (${item.quantity })`).join(', ')
            row['סטטוס'] = row.status
            delete row.createdAt
            delete row.branche
            delete row.worker_name
            delete row.worker_role
            delete row.owner
            delete row.email
            delete row.id
            delete row.signature_path
            delete row.items
            delete row.status
            delete row.orderId
            delete row.idNumber
          })
          let workSheet = XLSX.utils.json_to_sheet(rows);
            let workbook = XLSX.utils.book_new();
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "ביגוד");
            XLSX.writeFile(workbook, "ביגוד.xlsx");
        }
        const deleteOrderFinal=async()=>{
            try{
                
                await projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection("Orders")
                .doc(selected_record.value.orderId)
                .delete()

                alert('success','סטטוס הזמנה','הזמנה זו נמחקה בהצלחה')
                .then(async()=>{
                    await decrement(selected_record.value.status,'wH3Q9T5lhhJ0PsdpBN1J')
                    showOrderDelete.value=!showOrderDelete.value
                    show_record.value=!show_record.value
                    const index = records.value.findIndex(record=>record.orderId == selected_record.value.orderId )
                    if(index!=-1){
                       records.value.splice(index,1) 
                    }
                })
            }catch(err){
                swal(`${err.message}`, "סטטוס הזמנה לא השתנה עקב תקלה נא צור קשר עם תמיכה הטכנית", "error");
            }
        }
        const deleteOrderRestore =async()=>{
            try{
                await projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection("Orders")
                .doc(selected_record.value.orderId)
                .delete()

                selected_record.value.items.forEach((item)=>{
                    const barkod = item.barkod
                    projectFirestore
                    .collection("Applications")
                    .doc('wH3Q9T5lhhJ0PsdpBN1J')
                    .collection('Products')
                    .doc(barkod.toString())
                    .set({
                        stock: firebase.firestore.FieldValue.increment(Number(item.quantity))
                    },{merge:true})
                })
                alert('success','נמחק בהצלחה','מלאי עודכן בהצלחה')
                .then(async()=>{
                    await decrement(selected_record.value.status,'wH3Q9T5lhhJ0PsdpBN1J')
                    showOrderDelete.value=!showOrderDelete.value
                    show_record.value=!show_record.value
                    const index = records.value.findIndex(record=>record.orderId == selected_record.value.orderId )
                    if(index!=-1){
                       records.value.splice(index,1) 
                    }
                })
            }catch(err){
                alert('error','התרחשה שגיאה',`${err.message}`)
            }

     
        }

        //dont delete this comment this is important
        // const printOrder=()=>{
        //     const divContents = document.getElementById("orderToPrint").innerHTML;
        //     var a = window.open('', '', 'height=800, width=600'); 
        //     a.document.write('<html>'); 
        //     a.document.write('<style>'); 
        //     a.document.write(`@media print{#print-order-hide {visibility: hidden; } .sign{display: list-item; list-style-image: url(${selected_record.value.signature}); list-style-position: inside;} #delete-order-hide { visibility: hidden; } #mark-btn-hide {visibility: hidden;}}`)
        //     a.document.write('</style>'); 
        //     a.document.write(divContents); 
        //     a.document.write('</body></html>'); 
        //     a.document.close(); 
        //     a.print(); 
        // }

        const printOrder = () =>{
          var styleElem = document.createElement('style');
          styleElem.type = 'text/css' ;
          var css = `
                      @media print{
                      #print-order-hide {visibility: hidden;} 
                      .sign{
                        display: list-item; 
                        list-style-image: url(${selected_record.value.signature});
                        list-style-position: inside;
                      } 
                      #delete-order-hide { visibility: hidden; } 
                      #mark-btn-hide {visibility: hidden;}
                      #orderToPrint{display:block;}
                      }
                    `;
          if(styleElem.styleSheet){
              styleElem.styleSheet.cssText = css;
          }
          else{
              styleElem.appendChild(document.createTextNode(css));
          }
          var divToPrint=document.getElementById("orderToPrint").innerHTML;
          let newWin = window.open('', '', 'height=800, width=600'); 
          newWin.document.write(divToPrint);
          newWin.document.getElementsByTagName('head')[0].appendChild(styleElem);
          newWin.document.close();
          newWin.print();
        }


        const flagOrderFinished=async()=>{
          if(selected_status.value!='' && (selected_record.value.status!=selected_status.value.code)){
            let obj={}
            if(selected_status.value.code == 'delivery'){
              obj.status = selected_status.value.code
              obj.deliveryDate = new Date()
            }else{
               obj.status = selected_status.value.code
               obj.deliveryDate = null
            }
            try{
                await projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection("Orders")
                .doc(selected_record.value.orderId)
                .set(obj,{merge:true})
                
                alert('success',
                'סטטוס הזמנה',
                `הפכת סטטוס הזמנה זו ל${selected_status.value.name}`)
                .then(async()=>{
                  await update_status_counter(selected_record.value.status,selected_status.value.code,'wH3Q9T5lhhJ0PsdpBN1J')
                  sendEmailToUser(selected_record.value.email)
                  show_record.value = false
                  const index = records.value.findIndex(record=>record.orderId == selected_record.value.orderId)
                  if(index!=-1){
                      records.value.splice(index,1)
                  }
                })
            }catch(err){
                alert('error',`${err.message}`,'סטטוס הזמנה לא השתנה עקב תקלה נא צור קשר עם תמיכה הטכנית')
            }
          }
        }
        const handle_choosed_record = (record)=>{
            selected_record.value = record
            show_record.value = !show_record.value
        }
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const sorted_records = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_records_by_search.value;
            if (selectedSorted.value.name == "תאריך הבקשה") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.createdAt.toDate() >  b.createdAt.toDate() 
                        ? 1
                        : b.createdAt.toDate()  > a.createdAt.toDate() 
                        ? -1
                        : 0;
                    }
                    return a.createdAt.toDate() > b.createdAt.toDate() 
                    ? -1
                    : b.createdAt.toDate()  > a.createdAt.toDate() 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "סניף") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.branche >  b.branche 
                        ? 1
                        : b.branche  > a.branche 
                        ? -1
                        : 0;
                    }
                    return a.branche > b.branche 
                    ? -1
                    : b.branche  > a.branche 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם העובד") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.worker_name >  b.worker_name 
                        ? 1
                        : b.worker_name  > a.worker_name 
                        ? -1
                        : 0;
                    }
                    return a.worker_name > b.worker_name 
                    ? -1
                    : b.worker_name  > a.worker_name 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "תפקיד") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.worker_role >  b.worker_role 
                        ? 1
                        : b.worker_role  > a.worker_role 
                        ? -1
                        : 0;
                    }
                    return a.worker_role > b.worker_role 
                    ? -1
                    : b.worker_role  > a.worker_role 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "פותח ההזמנה") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.owner >  b.owner 
                        ? 1
                        : b.owner  > a.owner 
                        ? -1
                        : 0;
                    }
                    return a.owner > b.owner 
                    ? -1
                    : b.owner  > a.owner 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "דואל") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.email >  b.email 
                        ? 1
                        : b.email  > a.email 
                        ? -1
                        : 0;
                    }
                    return a.email > b.email 
                    ? -1
                    : b.email  > a.email 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מס מוצרים") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.items.length >  b.items.length 
                        ? 1
                        : b.items.length  > a.items.length 
                        ? -1
                        : 0;
                    }
                    return a.items.length > b.items.length 
                    ? -1
                    : b.items.length  > a.items.length 
                    ? 1
                    : 0;
                });
            }
        }));
        
        const filter_records_by_search = ref(computed(()=>{
            if(search.value == '') {
                if(fromDate.value && toDate.value){
                    return records.value.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                        stringTodate(toDate.value)>=timestampToDate(record.createdAt) ){
                            return record
                        }
                    })
                }
                return records.value;
            }
            else{
                let results= records.value.filter(record=>{
                    if(new Date(record.createdAt.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record
                    if (record.branche.includes(search.value)) return record;
                    if (record.worker_name.includes(search.value)) return record;
                })
                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                        stringTodate(toDate.value)>=timestampToDate(record.createdAt) ){
                            return record
                        }
                    })
                }else{
                    return results
                }
            }
        }))
       
        const handleClearDates=()=>{
            fromDate.value=''
            toDate.value=''
        }

        const handle_records = async(status)=>{
            isPending.value = true
            localStorage.setItem("Bigud_status",status);
            const docs = await projectFirestore.collection('Applications').doc('wH3Q9T5lhhJ0PsdpBN1J')
            .collection('Orders').where('status','==',status).get()
            records.value = docs.docs.map(doc=>doc.data())
            isPending.value = false
        }

        // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        const handleDeleteChecks=async(option)=>{
            if(option=='לא'){
                showDeleteDialog.value=false
                check_records.value=[]
                checkeAll.value=false
            }
            if(option=='כן'){
                isPending.value = true
                for (const recordId of check_records.value){
                   
                    await delete_record(recordId)

                    const index = records.value.findIndex(_record=>_record.orderId == recordId)
                    if(index!=-1){
                      await decrement(records.value[index].status,'wH3Q9T5lhhJ0PsdpBN1J')
                      records.value.splice(index,1)
                    }
                }
                showDeleteDialog.value=false
                check_records.value=[]
                isPending.value=false
                checkeAll.value=false
            }
        }

        const delete_record = async(recordId)=>{
            await projectFirestore.collection('Applications').doc('wH3Q9T5lhhJ0PsdpBN1J')
            .collection('Orders').doc(recordId).delete()
        }

        const initial = async()=>{
            let status='open'
            if(localStorage.getItem('Bigud_search')){
                    search.value = localStorage.getItem('Bigud_search')
            }
            if(localStorage.getItem("Bigud_status")){
                    status=localStorage.getItem("Bigud_status")
            }
            await handle_records(status)
        }

        let counter_unsub
        const counter_records_RT=()=>{
           counter_unsub = projectFirestore.collection('Applications').doc('wH3Q9T5lhhJ0PsdpBN1J')
           .onSnapshot(doc=>{
               counter_values.value = doc.data()
           })
        }

      function sendEmailToUser(email) {
        projectFunctions.httpsCallable('send_email')({
        html:document.getElementById("form-email").innerHTML,
        destinations:[`${email}`],
        from:`סטטוס הזמנת ביגוד עבור ${selected_record.value.worker_name}`,
        title:`הזמנת ביגוד לסניף ${selected_record.value.branche}, ${selected_record.value.worker_name}`
        })
      }

        watch(search,()=>{
            localStorage.setItem("Bigud_search",search.value);
        })
        watch(checkeAll,()=>{
            if(checkeAll.value){
                check_records.value=sorted_records.value.map(record=>record.orderId)
            }
            else{
                check_records.value=[]
            }
        })
        watch(check_records,()=>{
            if(check_records.value.length>0){
                showDeleteDialog.value=true
            }
            else {
                showDeleteDialog.value=false
            }
        })
        
        
        window.addEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
        });
        onMounted(async()=>{
          if(window.innerWidth<600){
            desktop.value = false
          }else{
            desktop.value = true
          }
          await initial()
          counter_records_RT()
           
        })

        onUnmounted(()=>{
          window.removeEventListener('resize', ()=>{
            if(window.innerWidth<600){
                desktop.value = false
            }else{
                desktop.value = true
            }
          });
          if(counter_unsub){
            counter_unsub()
          }
        })
        
        return{
            desktop,
            handleClearDates,
            handle_records,
            handleSortTable,
            handleDeleteChecks,
            handle_choosed_record,
            deleteOrderRestore,
            deleteOrderFinal,
            flagOrderFinished,
            handleExportToCsv,
            sorted_records,
            isPending,
            records,
            search,
            fromDate,
            toDate,
            filter_records_by_search,
            selectedSorted,
            checkeAll,
            check_records,
            showDeleteDialog,
            selected_record,
            show_record,
            user,
            printOrder,
            showOrderDelete,
            selected_status,
            status_options,
            counter_values,
            sendEmailToUser,
            sendEmailToUser
        }
    }
}
</script>

<style scoped>
    .orders-summery {
      position: relative;
      width: 80vw;
      height: 80vh;
    }
    .header{
        padding: 1.5rem;
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    .dashboard{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
    }
    .dashboard .frame{
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
    }
    .dashboard .total{
        background-color: teal;
    }
    .dashboard .news{
        background-color: #027ABC;
    }
    .dashboard .old{
        background-color: #5E8F32;
    }
    .dashboard .waiting{
        background-color: red
    }
    .dashboard .process{
        background-color: #FAB710;
        color: #333;
    }
    .dashboard .current{
        background-color: grey;
    }
    .display-btns{
        margin: 5px;
        width: 100%;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content{
        position: relative;
        width: 100%;
        height: 85%;
        overflow: hidden;
        overflow-y: auto;
    }
    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }

    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    #records td, #records th {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }
    #records tr:nth-child(even){
        background-color: #f2f2f2;
    }
    #records tr:hover {
        background-color: #ddd;
    }
    #records th {
        cursor: pointer;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #04AA6D;
        color: white;
    }
    i{
        cursor: pointer;
    }
    .signature{
      display: flex;
      align-items: center;
      padding: 2rem;
      margin-top: 15px;
    }

   


</style>