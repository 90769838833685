<template>
  <div class="container">
    <div class="header-btn">
      <!--  @click="showAddNewItem = !showAddNewItem" -->
      <Button
        :label="changeAddItemBtnContect"
        style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        class="p-button-warning"
        @click="showHideAddNewItemFunc"
      />
      <Button
        v-if="!showAddNewItem"
        label="הדפס"
        style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="printProducts"
      />
      <Button
        v-if="!showAddNewItem"
        :label="titleBtnForCheckbox"
        class="p-button-danger"
        style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="handleCheckBox"
      />
    </div>
    <div class="btn-for-checks-items" style="width:98%" v-if="showCheckBox">
      <Button
        class="p-button-danger"
        label="שלח פריטים מסומנים להדפסה"
        style="width:100%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="printCheckedItems"
      />
    </div>
    <div class="btn-for-visible-items" style="width:98%">
      <Button
        v-if="!showAddNewItem"
        class="p-button-secondary"
        :label="computedNameForVisibleBtn"
        style="width:100%; height:35px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
        @click="handleVisibleItems"
      />
    </div>
    <div v-if="!showAddNewItem" class="search-bar" style="width:100%">
      <span class="p-input-icon-left" style="width:100%">
        <i class="pi pi-search" />
        <InputText
          type="text"
          v-model="searchString"
          placeholder="חפש"
          style="width:100%"
        />
      </span>
    </div>
    <div v-if="!showAddNewItem" class="sortBy" style="margin-top:0.5rem; width:100%">
      <Dropdown
        v-model="selectedSortBy"
        :options="sortByOptions"
        placeholder="מיין לפי"
        style="width:100%"
      />
    </div>
    <div class="test" style="height:60px;">
      <Button
        v-if="!showAddNewItem"
        label="עדכן"
        style="width:100%; height:35px; font-size:1.25rem; margin-top:0.5rem;"
        class="p-button-success"
        @click="showStockBeforeUpdate"
      />
    </div>
    <div v-if="showAddNewItem">
      <NewProductBigud @hide="showAddNewItem = !showAddNewItem" />
    </div>
    <div v-else>
      <div class="product-list" v-if="products">
        <template v-for="product in sortedProducts" :key="product.ItemName">
          <div class="product-line" :id="product.barkod">
            <div class="check-box" v-if="showCheckBox">
              <Checkbox
                name="itemChecks"
                :value="product"
                v-model="itemsChecks"
              />
            </div>

            <div class="barkod-and-name">
              <div class="product-id" style="">
                {{ product.barkod }}
              </div>
              <div
                class="product-name"
                style="font-size: 1rem; overflow:hidden; "
              >
                <p v-if="product.stock > 0">{{ product.ItemName }}</p>
                <p v-else style="color:red;">{{ product.ItemName }}</p>
              </div>
            </div>

            <div class="icons">
              <div class="update-item">
                <i
                  class="pi pi-pencil"
                  style=" color:black; font-size: 1.2rem;"
                  @click="updateItem(product.barkod.toString())"
                ></i>
              </div>
              <div class="delete-item">
                <i
                  class="pi pi-trash"
                  style=" color:red; font-size: 1.2rem;"
                  @click="
                    deleteItem(product.barkod.toString(), product.filePath)
                  "
                ></i>
              </div>
            </div>

            <div class="product-stock" style="font-size: 1.2rem;">
              <InputText
                type="text"
                v-model="product.tempStock"
                style="width:100%"
                :placeholder="product.openStock"
              />
            </div>
          </div>
          <p style="color:red; text-align:center;">
            מלאי מעודכן: {{ product.stock }}
          </p>
          <div class="divider-line"></div>
        </template>

        <!-- just for print -->
        <div class="print" ref="print" v-show="false">
          <div class="headlines">
            <p class="barkod" style="width:25%">ברקוד</p>
            <p class="name" style="width:25%">שם מוצר</p>
            <p class="qnt" style="width:25%">מחיר אחרון</p>
            <p class="qnt" style="width:25%">כמות</p>
          </div>

          <template v-for="product in productsToShow" :key="product.barkod">
            <div class="product-line" :id="product.barkod">
              <div class="product-id" style="width:25%;">
                {{ product.barkod }}
              </div>
              <div
                class="product-name"
                style="font-size: 1rem; width:25%; overflow:hidden;"
              >
                <p v-if="product.stock > 0">{{ product.ItemName }}</p>
                <p v-else style="color:red;">{{ product.ItemName }}</p>
              </div>
              <div class="product-last-price" style="width:25%;">
                {{ product.lastPrice }}
              </div>
              <div class="product-stock" style="font-size: 1.2rem; width:25%;">
                <InputText
                  type="text"
                  v-model="amount[product.barkod]"
                  style="width:80%;"
                  :placeholder="product.stock"
                />
              </div>
            </div>
          </template>
        </div>

        <!-- just print checked items -->
        <div class="print" ref="printChecked" v-show="false">
          <div class="headlines">
            <p class="barkod" style="width:25%">ברקוד</p>
            <p class="name" style="width:25%">שם מוצר</p>
            <p class="qnt" style="width:25%">מחיר אחרון</p>
            <p class="qnt" style="width:25%">כמות</p>
          </div>
          <template v-for="product in itemsChecks" :key="product.barkod">
            <div class="product-line" :id="product.barkod">
              <div class="product-id" style="width:25%;">
                {{ product.barkod }}
              </div>
              <div
                class="product-name"
                style="font-size: 1rem; width:25%; overflow:hidden;"
              >
                <p v-if="product.stock > 0">{{ product.ItemName }}</p>
                <p v-else style="color:red;">{{ product.ItemName }}</p>
              </div>
              <div class="product-last-price" style="width:25%;">
                {{ product.lastPrice }}
              </div>
              <div class="product-stock" style="font-size: 1.2rem; width:25%;">
                <InputText
                  type="text"
                  v-model="amount[product.barkod]"
                  style="width:80%;"
                  :placeholder="product.stock"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <Dialog
      header="עריכת פריט"
      v-model:visible="showEditWindow"
      :modal="true"
      position="top"
    >
      <div
        class="delete-order"
        style="display:flex; flex-direction:column; width:300px; height:80vh; margin-top:1rem"
      >
        <label>ברקוד:</label>
        <InputText
          type="text"
          v-model="itemToEdit.barkod"
          :placeholder="itemToEdit.barkod"
          style="width:100%; min-width:160px;"
        />
        <label>שם מוצר:</label>
        <InputText
          type="text"
          v-model="itemToEdit.ItemName"
          :placeholder="itemToEdit.ItemName"
          style="width:100%; min-width:160px;"
        />
        <label>מחיר אחרון:</label>
        <InputText
          type="number"
          v-model="itemToEdit.lastPrice"
          :placeholder="itemToEdit.lastPrice"
          style="width:100%; min-width:160px;"
        />

        <label>הסתר פריט:</label>
        <Dropdown
          v-model="selectVisibilty"
          :options="visibiltyOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="הסתר פריט"
        />
        <label>סוג פריט:</label>
        <Dropdown
          v-model="itemToEdit.selectedType"
          :options="itemsTypesOptions"
          placeholder="בחר סוג פריט"
        />
        <label>תפקיד:</label>

        <MultiSelect
          v-model="itemToEdit.selectedRole"
          :options="roles"
          placeholder="תפקידים"
          display="chip"
          style="width:100%; margin-bottom: 1rem;"
        />

        <p style="color: red;">{{ fileError }}</p>
        <!-- @click="updateItemFinish" -->
        <Button
          label="עדכן פריט בשרת"
          style="width:100%; height:40px; font-size: 1.1rem;"
          class="p-button-info"
          @click="updateItemFinish"
        />
      </div>
    </Dialog>

    <Dialog
      header="נא לוודא!"
      v-model:visible="displayConfirmation"
      :style="{ width: '100%' }"
      :modal="true"
    >
      <div class="confirmation-content" style="margin-top:1rem ;">
        <strong>האם אתה בטוח שברצונך לעדכן פרטים אלה?</strong>
      </div>
      <div class="products-before-update">
        <table>
          <tr>
            <th>ברקוד</th>
            <th>שם מוצר</th>
            <th>מלאי לפני עדכון</th>
            <th>מלאי לאחר עדכון</th>
          </tr>
          <template
            v-for="product in productsListBeforeUpdate"
            :key="product.barkod"
          >
            <tr>
              <td>{{ product.barkod }}</td>
              <td>{{ product.ItemName }}</td>
              <td>{{ product.stock }}</td>
              <td>{{ product.tempStock }}</td>
            </tr>
          </template>
        </table>
      </div>

      <div class="dialog-footer">
        <Button
          label="לא"
          @click="displayConfirmation = !displayConfirmation"
          class="p-button-danger"
        />
        <Button label="כן" @click="updateStock" class="p-button-success" />
      </div>
    </Dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import NewProductBigud from './NewProductBigud.vue'
import InputText from "primevue/inputtext";
import {projectFirestore} from '../../../../firebase/config'
import Dialog from "primevue/dialog";
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import MultiSelect from "primevue/multiselect";
import {alert,alert_confirm} from '../../../../Methods/Msgs'
export default {
    components:{NewProductBigud,InputText,Dialog,Checkbox,Dropdown,MultiSelect},
    setup(){


        const roles=ref([
            'קצבייה',
            'קופה',
            'מחסן',
            'שמירה',
            'מעדנייה',
            'מנהל/סגן',
            'סדרנות'
          ])
        const itemsTypesOptions=ref([
          'ללא סוג',
          'מרקט',
          'מעדני רוסמן',
          'לוגיסטיקה',
          'ביוטי פארם',
          'סטוק סיטי',
          
        ])

        const selectVisibilty=ref('true')
        const visibiltyOptions=ref([
          {name:'כן',value:'false'},
          {name:'לא',value:'true'},
        ])


        const showVisibleItems = ref(true)

        const handleVisibleItems =ref(()=>{  
          showVisibleItems.value=!showVisibleItems.value
          productsToShow.value=products.value.filter(item=>item.visible==`${showVisibleItems.value}`)
        })

        const computedNameForVisibleBtn=ref(computed(()=>{
          if(showVisibleItems.value){
            return 'הצג פריטים מוסתרים'
          }
          return 'הצג פריטים שאינם מוסתרים'
        }))

        
        const printChecked=ref('')
        const showCheckBox=ref(false)
        const itemsChecks=ref([])
        const searchString=ref('')
        const fileError=ref(null)
        const file = ref(null)
        const displayConfirmation=ref(false)
        const productsListBeforeUpdate=ref([])
        const itemToEdit=ref({})
        const print=ref(null)
        const amount=ref({})
        const products=ref([]);
        const showEditWindow = ref(false);
        const titleBtnForCheckbox=ref("הזמן מלאי")
        const sortByOptions=ref(['שם מוצר','ברקוד','כמות',])
        const selectedSortBy=ref('מיין לפי')


        const handleCheckBox=()=>{
          showCheckBox.value=!showCheckBox.value
          if(titleBtnForCheckbox.value=="הזמן מלאי"){
            titleBtnForCheckbox.value="ביטול"
          }else{
            titleBtnForCheckbox.value="הזמן מלאי"
          }
        }
        var sortedProducts = ref(computed(()=>{
            if(searchString.value==""){
              productsToShow.value = products.value.filter(product=>product.visible==`${showVisibleItems.value}`)
              return productsToShow.value
            }else{
              let temp=[]
              if(Number(searchString.value)>0){
                temp=products.value.filter(product=>product.barkod.includes(searchString.value) && product.visible==`${showVisibleItems.value}`)
                productsToShow.value = temp
                return productsToShow.value
              }else{
                temp=products.value.filter(product=>product.ItemName.includes(searchString.value) && product.visible==`${showVisibleItems.value}`)
                productsToShow.value = temp
                return productsToShow.value
              }
            }
        }));

        let productsToShow = ref([]);


        watch(selectedSortBy, () => {
          if(selectedSortBy.value == 'ברקוד'){
            productsToShow.value.sort((itemA, itemB) => {       
              if(Number(itemA.barkod) < Number(itemB.barkod)){return -1}
              if(Number(itemA.barkod) > Number(itemB.barkod)){return 1}
              return 0
            })
          }
          if(selectedSortBy.value == 'שם מוצר'){
         
            productsToShow.value.sort((itemA, itemB) => {
              if(itemA.ItemName < itemB.ItemName){return -1}
              if(itemA.ItemName > itemB.ItemName){return 1}
              return 0
            })
          }
          if(selectedSortBy.value == 'כמות'){
            productsToShow.value = productsToShow.value.sort((itemA, itemB) => {
              if(itemA.stock < itemB.stock){return -1}
              if(itemA.stock > itemB.stock){return 1}
              return 0
            })
          }
          
        })

        //allowed file types
        const types = ['image/png','image/jpeg','image/jpg']
        const handleChangeFile=(e)=>{
          const selected=e.target.files[0]

          if(selected && types.includes(selected.type)){
            file.value = selected
            fileError.value=null
          }else{
            file.value=null
            fileError.value = 'אנא העלה תמונה בפורמט PNG או JPG'
          }

        }

        const showStockBeforeUpdate=()=>{
          let error=false
          const productsWithTempStock=products.value.filter((product)=>product.tempStock)
          //validation
          productsWithTempStock.forEach(item=>{
            if(isNaN(item.tempStock) || item.tempStock<0){
              alert('error','שגיאה','אחד מהנתונים שהזנת אינו תקין')
              error=true
              return 
            }
          })
          if(!error){
            displayConfirmation.value=!displayConfirmation.value
            productsListBeforeUpdate.value=productsWithTempStock
          }
        }

        const updateStock= ()=>{
        productsListBeforeUpdate.value.forEach(async(product)=>{
            try{
                await projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection('Products')
                .doc(product.barkod.toString())
                .update({
                  stock: product.tempStock,
                  openStock: product.tempStock
                })
 
            }catch(err){
                console.log(err.message);
                swal("אירעה שגיאה", `${err.message}`, "error");
            }       
        })
         alert('success','עודכן בהצלחה','עדכנת את המלאים בהצלחה')
         .then(()=>{
            displayConfirmation.value=!displayConfirmation.value
         })
        }

       
        const deleteItem=(id)=>{
            alert_confirm('האם בטוח שברצנוך למחוק פריט זה?')
            .then(async(res) => {
                if (res.isConfirmed) {
                    try{                
                        await projectFirestore.
                        collection("Applications")
                        .doc("wH3Q9T5lhhJ0PsdpBN1J")
                        .collection('Products')
                        .doc(id)
                        .delete()
                    }catch(err){
                        console.log(err.message);
                    }
                }
            });
        }

        let unsub
        const getProducts=()=>{
             unsub = projectFirestore.
                collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection('Products')
                .orderBy('ItemName')
                .onSnapshot(snap=>{
                    const results = [];
                    snap.docs.forEach(doc=>{
                        results.push({...doc.data()})
                    })
                    products.value=results
                })         
        }
        onMounted(()=>{
            getProducts()
        })

        onUnmounted(()=>{
            if(unsub){
                unsub()
            }
        })
        
        const showAddNewItem=ref(false)


        // print
        const printProducts=()=>{
            var divContents = print.value.innerHTML 
            var a = window.open('', '', 'height=500, width=500'); 
            a.document.write('<html>'); 
            a.document.write('<style>'); 
            a.document.write('*{direction:rtl} .headlines{display: flex; font-weight: bold; } *{font-family: "Rubik", sans-serif;} .product-line {display: flex; align-items:center;}')
            a.document.write('</style>'); 
            a.document.write('<body > <div class="header"><h1 style="text">רשימת פריטים ירקות</h1> </div><br>'); 
            a.document.write(divContents); 
            a.document.write('</body></html>'); 
            a.document.close(); 
            a.print(); 
        }

        // print only checked items
        const printCheckedItems=()=>{
            if(itemsChecks.value.length==0){
              swal({
                    title: "עליך לבחור פריטים",
                    text: "לא ניתן להדפיס פריטים כאשר הם אינם מסומנים",
                    icon: "error",
                    button: "סגור",
                });
            }else{
                var divContents = printChecked.value.innerHTML 
                var a = window.open('', '', 'height=500, width=500'); 
                a.document.write('<html>'); 
                a.document.write('<style>'); 
                a.document.write('*{direction:rtl} .headlines{display: flex; font-weight: bold; } *{font-family: "Rubik", sans-serif;} .product-line {display: flex; align-items:center;}')
                a.document.write('</style>'); 
                a.document.write('<body > <div class="header"><h1 style="text">רשימת מלאי להזמנה </h1> </div><br>'); 
                a.document.write(divContents); 
                a.document.write('</body></html>'); 
                a.document.close(); 
                a.print(); 
            }
            
        }

        const showHideAddNewItemFunc=()=>{
          showAddNewItem.value = !showAddNewItem.value
        //   showPrintBtn.value=!showPrintBtn.value
        //   showSearch.value=!showSearch.value
        //   showSortBy.value=!showSortBy.value
          
        }

        const changeAddItemBtnContect=ref(computed(()=>{
          if(!showAddNewItem.value){
            return "הוסף פריט"
          }else{
            return "סגור"
          }
        }))

        
        const currentBarkod=ref('')
        const updateItem=(id)=>{
           let index = products.value.findIndex(product => product.barkod == id);
           itemToEdit.value = {...products.value[index]};
           currentBarkod.value=itemToEdit.value.barkod
           console.log('curent barkod:',currentBarkod.value);
           console.log(itemToEdit.value);
           showEditWindow.value = true;
        }
        
        const updateItemFinish=async()=>{
         
          if(itemToEdit.value.barkod==currentBarkod.value){
             try{
                projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection('Products')
                .doc(itemToEdit.value.barkod)
                .update({
                    ItemName: itemToEdit.value.ItemName,
                    lastPrice: itemToEdit.value.lastPrice,
                    visible: selectVisibilty.value,
                    selectedType:itemToEdit.value.selectedType,
                    selectedRole:itemToEdit.value.selectedRole
                  })

                  showEditWindow.value=!showEditWindow.value
                  swal({
                        title: `הפריט עודכן ל${itemToEdit.value.ItemName} בהצלחה`,
                        icon: "success",
                        button: "סגור",
                    });

              }catch(err){
                swal({
                        title: "אירעה שגיאה בעדכון הפריט",
                        text: `${err.message}`,
                        icon: "error",
                        button: "סגור",
                    });
              }


          }else{
            try{
               projectFirestore.
              collection("Applications")
              .doc("wH3Q9T5lhhJ0PsdpBN1J")
              .collection('Products')
              .doc(currentBarkod.value)
              .delete()

              projectFirestore
                  .collection("Applications")
                  .doc("wH3Q9T5lhhJ0PsdpBN1J")
                  .collection('Products')
                  .doc(itemToEdit.value.barkod)
                  .set({...itemToEdit.value})

              swal({
                    title: `הפריט עודכן ל${itemToEdit.value.ItemName} בהצלחה`,
                    icon: "success",
                    button: "סגור",
                });

              }catch(err){
                console.log(err.message);
                swal({
                          title: "אירעה שגיאה בעדכון הפריט אנא פנה למשה",
                          text: `${err.message}`,
                          icon: "error",
                          button: "סגור",
                      });
              }
          }
         
          
        }

        return{
            roles,
            itemsTypesOptions,
            currentBarkod,
            computedNameForVisibleBtn,
            showVisibleItems,
            handleVisibleItems,
            selectVisibilty,
            visibiltyOptions,
            selectedSortBy,
            sortByOptions,
            titleBtnForCheckbox,
            printChecked,
            printCheckedItems,
            showCheckBox,
            handleCheckBox,
            itemsChecks,
            searchString,
            fileError,
            handleChangeFile, 
            displayConfirmation,
            showStockBeforeUpdate,
            productsListBeforeUpdate,
            updateItemFinish,
            itemToEdit,
            showEditWindow,
            updateItem,
            changeAddItemBtnContect,
            showHideAddNewItemFunc,
            showAddNewItem,
            products,
            sortedProducts,
            deleteItem,
            amount,
            updateStock,
            printProducts,
            print,
            productsToShow
            }
    }

}
</script>

<style scoped>
.product-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  overflow-y: auto;
  gap: 15px;
  border-radius: 3px;
  margin-top: 20px;
}
.product-line {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  align-content: start;
}

.header-btn{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
}
i{
    cursor: pointer;
}

.products-before-update table{
  margin: 1rem 0;
  font-size: 1.1rem;
  border-collapse: collapse;
  width: 100%;
}

.products-before-update td,th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

.products-before-update table td:last-child,th:last-child{
  color: red;
}
.products-before-update tr:nth-child(even){
  background-color: #dddddd;
}

.dialog-footer{
  display: flex;
  justify-content: space-evenly;
}

.container{
  height: 70vh;
  width: 80vw;
}

.product-image{
  overflow: hidden;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.product-image img{
  width: 80px;
  height: auto;
  max-height: 80px;
}

.barkod-and-name, .icons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider-line{
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.288);
}

.check-box{
  position: absolute;
  top: 5px;
  right: 5px;
}

</style>