import {projectFirestore,firebase} from '../firebase/config'

export const increment = async (docId)=>{
    await projectFirestore.collection('Applications').doc(docId)
    .update({
        open:firebase.firestore.FieldValue.increment(1),
        total:firebase.firestore.FieldValue.increment(1)
    })
}

export const decrement = async (status,docId)=>{
    await projectFirestore.collection('Applications').doc(docId)
    .update({
        [status]:firebase.firestore.FieldValue.increment(-1),
        total:firebase.firestore.FieldValue.increment(-1)
    })
}

export const update_status_counter = async(old_status,new_status,docId)=>{
    await projectFirestore.collection('Applications').doc(docId)
    .update({
        [old_status]:firebase.firestore.FieldValue.increment(-1),
        [new_status]:firebase.firestore.FieldValue.increment(1)
    })
}



