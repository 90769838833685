<template>
  <div class="add-new-item">
    <InputText
      type="text"
      v-model="newProduct.ItemName"
      placeholder="רשום שם פריט"
      style="width:90%; margin-bottom: 1rem;"
    />
    <InputText
      type="text"
      v-model="newProduct.barkod"
      placeholder="רשום ברקוד פריט"
      style="width:90%; margin-bottom: 1rem;"
    />
    <InputText
      type="number"
      v-model="newProduct.stock"
      placeholder="רשום מלאי פתיחה"
      style="width:90%; margin-bottom: 1rem;"
    />
    <InputText
      type="number"
      v-model="newProduct.lastPrice"
      placeholder="רשום מחיר אחרון"
      style="width:90%; margin-bottom: 1rem;"
    />

    <MultiSelect
      v-model="newProduct.selectedRole"
      :options="roles"
      placeholder="תפקידים"
      display="chip"
      style="width:90%; margin-bottom: 1rem;"
    />
    <Dropdown
      v-model="newProduct.selectedSizes"
      :options="sizes"
      placeholder="בחר מידה"
      style="width:90%; margin-bottom: 1rem;"
    />
    <Dropdown
      v-model="newProduct.selectedType"
      :options="types"
      placeholder="בחר סוג"
      style="width:90%; margin-bottom: 1rem;"
    />

    <Button
      label="הוסף"
      style="width:90%; height:50px; font-size: 1.2rem; margin-top: 1rem"
      class="p-button-success"
      @click="addNewItem"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue'
import InputText from "primevue/inputtext";
import {projectFirestore} from '../../../../firebase/config'
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
export default {
    components:{InputText,Dropdown,MultiSelect},
    emits:['hide'],
    setup(_,{ emit }){
        class Product{
            constructor(ItemName,barkod,stock,lastPrice,selectedRole){
                this.barkod=barkod
                this.ItemName=ItemName
                this.stock=stock
                this.lastPrice=lastPrice
                this.selectedRole=selectedRole
                this.visible="true"
            }
        }
        const roles=ref([
            'קצבייה',
            'קופה',
            'מחסן',
            'שמירה',
            'מעדנייה',
            'מנהל/סגן',
            'סדרנות'
        ])
        const sizes=ref([
          'Y',
          'S',
          'M',
          'L',
          'XL',
          'XXL',
          'XXXL',
          'XXXXL',
          '36',
          '37',
          '38',
          '39',
          '40',
          '41',
          '42',
          '43',
          '44',
          '45',
          '46',
          '47',
          '48',
        ])

        const types = ref([
          'ללא סוג',
          'לוגיסטיקה',
          'מרקט',
          'מעדני רוסמן',
          'ביוטי פארם',
          'סטוק סיטי',
        ])
        const selectedRole=ref(null)
        const newProduct = ref(new Product);
     

        const addNewItem=async()=>{
            try{
                await projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection('Products')
                .doc(newProduct.value.barkod)
                .set((Object.assign({openStock:newProduct.value.stock}, newProduct.value)))
                emit('hide');
            }catch(err){
                console.log(err.message);
            }
           
        }
        
        return {types,newProduct,addNewItem,selectedRole,roles,sizes}
    }
}
</script>

<style scoped>
.add-new-item {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 90vw;
  margin-top: 1rem;
 
}
</style>